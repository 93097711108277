// https://patrickdesjardins.com/blog/typescript-with-strong-typed-action-when-using-usereducer-of-react-hooks

export interface ActionsWithoutPayload<TypeAction> {
    type: TypeAction;
}

export interface ActionsWithPayload<TypeAction, TypePayload> extends ActionsWithoutPayload<TypeAction> {
    payload: TypePayload;
}

export const createActionPayload = <TypeAction, TypePayload>(actionType: TypeAction): (payload: TypePayload) => ActionsWithPayload<TypeAction, TypePayload> => {
    return (p: TypePayload): ActionsWithPayload<TypeAction, TypePayload> => {
        return {
            payload: p,
            type: actionType
        };
    };
};

export const createAction = <TypeAction>(actionType: TypeAction): () => ActionsWithoutPayload<TypeAction> => {
    return (): ActionsWithoutPayload<TypeAction> => {
        return {
            type: actionType
        };
    };
};

interface ActionCreatorsMapObject {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: (...args: any[]) => ActionsWithPayload<any, any> | ActionsWithoutPayload<any>;
}

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;