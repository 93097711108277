import { LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import Loader from "../components/Loader";
import { AppDispatchContext } from "../contexts/Contexts";
import ApiActions from "../reducer/ApiActions";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { getConnection } from "./Functions";
import Settings from "../Settings";

const AuthLogout = () => {
    const auth0Props = useAuth0();
    const navigate = useNavigate();

    const appDispatch = React.useContext(AppDispatchContext);

    useEffect(() => {
        if (!auth0Props.isAuthenticated) {
            navigate(`/${window.location.search}`);
        }
        // eslint-disable-next-line
    }, [auth0Props.isAuthenticated]);

    const logout = useCallback(async () => {
        let query = "";

        const connection = localStorage.getItem("connection");
        if (connection != null) {
            query = `?connection=${connection}`;
        }
    
        const connectionClaims = await getConnection(auth0Props);
        const federatedLogout = connectionClaims?.federatedLogout || false;
        
        const logoutOptions: LogoutOptions = { 
            logoutParams: {
                federated: federatedLogout, 
                returnTo: `${window.location.origin}${query}`, 
                clientId: Settings.AUTH0_CLIENT_ID 
            }
        };

        appDispatch(ApiActions.logout({ federatedLogout: federatedLogout }));
        
        await auth0Props.logout(logoutOptions);
    }, [appDispatch, auth0Props]);

    useEffect(() => {
        if (auth0Props.isAuthenticated) {
            logout();
        }
    }, [ auth0Props.isAuthenticated, logout ]);

    return <Loader loading={true} label="Logging out" />;
};

export default AuthLogout;
