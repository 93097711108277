import React from 'react';
import AppRouterContainer from './AppRouterContainer';
import apiReducer from './reducer/ApiReducer';
import { AppDispatchContext, AppStateContext } from './contexts/Contexts';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
    const [state, appDispatch] = React.useReducer(apiReducer, 
        {
            isLoggedIn: false,
            hasSpexConnectPlusAccess: false,
            userName: undefined,
            jwt: undefined,
            federatedLogout: true
        });

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={appDispatch}>
                <AppRouterContainer />
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
}

export default App;
