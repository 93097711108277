import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import Settings from "../Settings";

const Auth0ProviderWithHistory = (props: React.PropsWithChildren) => {
    const domain = Settings.AUTH0_DOMAIN;
    const clientId = Settings.AUTH0_CLIENT_ID;
    let connection = Settings.AUTH0_CONNECTION;
    
    const navigate = useNavigate();

    const query = new URLSearchParams(window.location.search);
    let connectionFromQuery = query.get("connection") ?? connection;
    localStorage.setItem("connection", connectionFromQuery);

    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            onRedirectCallback={onRedirectCallback}
            authorizationParams={ { 
                connection: connectionFromQuery, 
                audience: Settings.AUTH0_AUDIENCE,
                redirect_uri: window.location.origin,
                scope: "openid profile email offline_access " + Settings.AUTH0_SCOPE,
                clientId: Settings.AUTH0_CLIENT_ID
            } }
            useRefreshTokens={true}
            cacheLocation="localstorage"

        >
            {props.children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
