import { createActionPayload } from "./ReducerAction";

export const ACTION_LOGIN_SUCCESS = 'ACTION_LOGIN_SUCCESS';
export const ACTION_LOGOUT = 'ACTION_LOGOUT';

const ApiActions = {
    loginSuccess: createActionPayload<typeof ACTION_LOGIN_SUCCESS, { userName: string, roles: string[], jwt: string }>(ACTION_LOGIN_SUCCESS),
    logout: createActionPayload<typeof ACTION_LOGOUT, { federatedLogout: boolean }>(ACTION_LOGOUT)
};

export default ApiActions;