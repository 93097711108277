import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./components/Loader";
import AppRouter from './AppRouter';

const AppRouterContainer = () => {
    const auth0Props = useAuth0();
   
    return (
        <Loader loading={auth0Props.isLoading} label="Logging in">
            <AppRouter />
        </Loader>
    );
};

export default AppRouterContainer;