import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../components/Loader";
import React, { useCallback, useEffect } from "react";
import { AppDispatchContext, AppStateContext } from "../contexts/Contexts";
import { getUserFullName, getUserRoles } from "./Functions";
import ApiActions from "../reducer/ApiActions";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings";

const AuthLogin = () => {
    const auth0Props = useAuth0();
    const navigate = useNavigate();

    const appState = React.useContext(AppStateContext);
    const appDispatch = React.useContext(AppDispatchContext);

    const getUserDetails = useCallback(async () => {
        const claims = await auth0Props.getIdTokenClaims();

        let userFullName = await getUserFullName(auth0Props);
        let roles = await getUserRoles(auth0Props, Settings.SPEX_SERVICE_CODE);

        appDispatch(ApiActions.loginSuccess({ roles: roles, userName: userFullName!, jwt: claims!.__raw }));
    }, [appDispatch, auth0Props]);

    useEffect(() => {
        const getTokenAsync = async () => {
            try {
                await auth0Props.getAccessTokenSilently();
                await getUserDetails();
            } catch (e: any) {
                auth0Props.loginWithRedirect({
                    appState: {
                        returnTo: '/AuthLogin'
                    }
                });
            }
        };

        if (!auth0Props.error) {
            if (!auth0Props.isAuthenticated) {
                auth0Props.loginWithRedirect({
                    appState: {
                        returnTo: '/AuthLogin'
                    }
                });
            } else {
                getTokenAsync();
            }
        }
        // eslint-disable-next-line
    }, [auth0Props.isAuthenticated, auth0Props.error]);

    useEffect(() => {
        if (appState.isLoggedIn) {
            navigate(`/SpexLogin${window.location.search}`);
        } else if (auth0Props.error) {
            navigate(`/403${window.location.search}`)
        }
        // eslint-disable-next-line
    }, [appState.isLoggedIn, auth0Props.error]);

    return <Loader loading={true} label="Logging in" />;
};

export default AuthLogin;
