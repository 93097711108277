import { Auth0ContextInterface, User } from "@auth0/auth0-react";

const CONNECTION_KEY = "https://auth.brandbank.com/connection";
const USER_KEY = "https://auth.brandbank.com/user";
const SERVICES_KEY = "https://auth.brandbank.com/services";

export const getConnection = async (props: Auth0ContextInterface<User>) => {
    const claims = await props.getIdTokenClaims();
    if (claims
        && claims[CONNECTION_KEY]
        && typeof claims[CONNECTION_KEY] != "undefined") {
        return JSON.parse(claims[CONNECTION_KEY]);
    }

    throw new Error('Missing connection claims');
};

export const getUserClaims = async (props: Auth0ContextInterface<User>) => {
    const claims = await props.getIdTokenClaims();
    if (claims
        && claims[USER_KEY]
        && typeof claims[USER_KEY] != "undefined") {
        return JSON.parse(claims[USER_KEY]);
    }

    throw new Error('Missing user claims');
};

export const getServices = async (props: Auth0ContextInterface<User>) => {
    const claims = await props.getIdTokenClaims();
    if (claims
        && claims[SERVICES_KEY]
        && typeof claims[SERVICES_KEY] != "undefined") {
        return JSON.parse(claims[SERVICES_KEY]);
    }

    throw new Error('Missing service claims');
};

export const getUserFullName = async (props: Auth0ContextInterface<User>) => {
    const userClaims = await getUserClaims(props);
    
    return userClaims.firstName + " " + userClaims.lastName;
};

export const getUserRoles = async (props: Auth0ContextInterface<User>, serviceCode: string) => {
    const services = await getServices(props);

    const serviceCodeRoles = services.find(
        (item: { serviceCode: string }) => item.serviceCode === serviceCode
    );

    if (serviceCodeRoles) {
        if (serviceCodeRoles.serviceRoles) {
            if (!serviceCodeRoles.subcodes) {
                serviceCodeRoles.subcodes = [];
            }

            serviceCodeRoles.subcodes.push({ subcode: 'VILB001', roles: serviceCodeRoles.serviceRoles });
        }

        if (serviceCodeRoles.subcodes) {
            const roles = serviceCodeRoles.subcodes.flatMap((sc: any) => sc.roles);

            return roles;
        }
    }

    return [];
};