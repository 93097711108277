import React from 'react';
import { Spinner } from 'react-bootstrap';

interface Props {
    loading: boolean;
    size?: 'sm';
    label?: string;
}

const Loader = (props: React.PropsWithChildren<Props>) => {
    if (props.loading) {
        return (
            <div className="loading-spinner absolute-center">
                <div className="spinner">
                    <Spinner 
                        animation="border" 
                        size={props.size} 
                        variant="primary"
                    />
                </div>
                <div className="padding" style={{ paddingLeft: '1em' }}>
                </div>
                <div className="label">
                    <span>{props.label ? props.label : 'Loading'}</span>
                </div>
            </div>
        );
    }

    return (
        <>
            {props.children}
        </>
    );
};

export default Loader;
