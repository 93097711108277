import { useNavigate } from "react-router";
import Loader from "../components/Loader";
import Settings from "../Settings";
import { useEffect } from "react";

interface Props {
    isAuthenticated: boolean;
    hasSpexConnectPlusAccess: boolean;
    jwt: string;
}

const SpexLogin = (props: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
       if (props.isAuthenticated 
            && props.hasSpexConnectPlusAccess) {
            const url = new URL(Settings.AUTH0_SPEX_URL);
            url.searchParams.append('token', props.jwt);
            
            window.location.assign(url);
        } else {
            navigate(`/${window.location.search}`);
        }
        // eslint-disable-next-line
    }, [ props.isAuthenticated, props.hasSpexConnectPlusAccess, props.jwt ]);

    return <Loader loading={true} label="Logging in" />;
};

export default SpexLogin;