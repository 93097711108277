import AuthLogin from "./auth/AuthLogin";
import { Route, Routes } from "react-router-dom";
import AccessDeniedError from "./AccessDeniedError";
import SpexLogin from "./auth/SpexLogin";
import LandingPage from "./LandingPage";
import AuthLogout from "./auth/AuthLogout";
import { Suspense } from "react";
import React from "react";
import { AppStateContext } from "./contexts/Contexts";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {

}

const AppRouter = (props: Props) => {
    const auth0Props = useAuth0();
    const appState = React.useContext(AppStateContext);

    let landingPage = <AuthLogin />;
    if (appState.isLoggedIn) {
        landingPage = <SpexLogin 
                            isAuthenticated={auth0Props.isAuthenticated} 
                            jwt={appState.jwt!} 
                            hasSpexConnectPlusAccess={appState.hasSpexConnectPlusAccess} 
                        />;
    } else if (!appState.federatedLogout) {
        landingPage = <LandingPage />;
    }

    return (
        <Suspense fallback={null}>
            <Routes>
                <Route path="/AuthLogin" element={<AuthLogin />} />
                <Route path="/SpexLogin" element={<SpexLogin 
                                                    isAuthenticated={auth0Props.isAuthenticated} 
                                                    jwt={appState.jwt!} 
                                                    hasSpexConnectPlusAccess={appState.hasSpexConnectPlusAccess} 
                                                />} />
                <Route path="/Logout" element={<AuthLogout />} />
                <Route path="/403" element={<AccessDeniedError />} />
                <Route path="*" element={landingPage} />
            </Routes>
        </Suspense>
    );
};

export default AppRouter;
