import React from "react";
import { useNavigate } from "react-router-dom";
import { AppStateContext } from "./contexts/Contexts";

const AccessDeniedError = () => {
    const navigate = useNavigate();

    const appState = React.useContext(AppStateContext);

    const navigateToHome = () => {
        navigate("/");
    };

    return (
        <React.Fragment>
            <div className="block-404-outer" data-testid="accessDeniedPage">
                <div className="block-404">
                    <div className="text-center mb-4">
                        <h5 className="sub-title mb-2">
                            You do not have access to the requested page.
                        </h5>
                        <small className="desc-text mx-auto">
                            You have been denied access to this page because you do not have
                            the correct security permissions.
                        </small>
                    </div>
                    {appState.hasSpexConnectPlusAccess && (
                        <div className="d-flex flex-column align-items-center">
                            <button className="btn btn-primary" data-testid="navigateToHome">
                                <span onClick={navigateToHome}>Back to home</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default AccessDeniedError;
