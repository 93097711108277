export const Settings = {
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || "",
    AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
    AUTH0_CONNECTION: process.env.REACT_APP_AUTH0_CONNECTION || "",
    AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || "",
    AUTH0_SCOPE: process.env.REACT_APP_AUTH0_SCOPE || "",
    AUTH0_SPEX_URL: process.env.REACT_APP_AUTH0_SPEX_URL || "",
    SPEX_SERVICE_CODE: process.env.REACT_APP_SPEX_SERVICE_CODE || "",
    LOGO_URL: process.env.REACT_APP_LOGO_URL || ""
  };
  
  export default Settings;
  