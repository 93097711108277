import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import Settings from "./Settings";

export interface Props {

}

const LandingPage = (props: Props) => {
    const navigate = useNavigate();

    return (
        <div id="appContainer">
            <div className="absolute-center">
                <div id="logo">
                    <img 
                        className="logo"
                        src={Settings.LOGO_URL} 
                        alt="NIQ Brandbank"
                    />
                </div>
                <div id="title">
                    Connect+
                </div>
                <div id="login">
                    <Button onClick={() => navigate(`/AuthLogin${window.location.search}`)}>Login</Button>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;