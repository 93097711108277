import AcceptedActions from "./AcceptedActions";
import AppState from "./AppState";

const apiReducer = (state: AppState, action: AcceptedActions): AppState => {
    const newState = {
        ...state
    };

    switch (action.type) {
        case "ACTION_LOGIN_SUCCESS":
            newState.isLoggedIn = true;
            newState.hasSpexConnectPlusAccess = action.payload.roles.length > 0;
            newState.userName = action.payload.userName;
            newState.roles = action.payload.roles;
            newState.jwt = action.payload.jwt;

            break;

        case "ACTION_LOGOUT":
            newState.isLoggedIn = false;
            newState.hasSpexConnectPlusAccess = false;
            newState.userName = undefined;
            newState.roles = undefined;
            newState.services = undefined;
            newState.federatedLogout = action.payload.federatedLogout;
            break;
    }

    return newState;
};

export default apiReducer;